var didScroll = false;


function setScrollState() {
  console.log('hey')
  didScroll = true;
}

setInterval(function() {
  if(didScroll) {
    didScroll = false;
  toggleHeader();
  }
}, 200);



function init() {
  MainMenu.init();
  document.querySelector('body').onscroll = function(){
    setScrollState();
  }
}

$(document).ready(init);
