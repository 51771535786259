var MainMenu = (function(){

	var init = function(){
		document.querySelector('div.nav-toggle').addEventListener('click', toggle);
		window.addEventListener('keydown', escapeKeyCloseMenu);
	},

	escapeKeyCloseMenu = function(event) {
		event = event || window.event;
		var isEscape = false;
    if ("key" in event) {
        isEscape = (event.key == "Escape" || event.key == "Esc");
    } else {
        isEscape = (event.keyCode == 27);
    }
    if (isEscape) {
			close();
		}
	},

	toggle = function(){	
		if (document.querySelector('.wrapper').classList.contains('st-menu-open')) {
			close();
		} else {
			open();
		}
	},
	
	open = function() {
		document.querySelector('body').style.overflowY = 'hidden';
		document.querySelector('.wrapper').classList.add('st-menu-open');
		document.querySelector('.wrapper').classList.add('st-effect-4');
	},

	close = function() {
		document.querySelector('body').style.overflowY = 'visible';
		document.querySelector('.wrapper').classList.remove('st-menu-open');
		document.querySelector('.wrapper').classList.remove('st-effect-4');
	};

	

	return {
		init: init
	}
})();
