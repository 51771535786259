var toggleHeader = function(){
	if (!document.querySelector('body').classList.contains('menu-active')){
		if (document.querySelector('.st-content-inner').getBoundingClientRect().top > -35){
			document.querySelector('.wrapper').classList.remove('header-fixed');
			document.querySelector('header').classList.remove('fixed');
			document.querySelector('.st-content-inner').style.paddingTop = 0;
		} else {
			document.querySelector('.wrapper').classList.add('header-fixed');
			document.querySelector('header').classList.add('fixed');
			document.querySelector('.st-content-inner').style.paddingTop = '150px';//`${document.querySelector('header').offsetHeight}px`;
		}
	}
};